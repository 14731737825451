<template>
  <div>
    <custom-module :formId="formId"></custom-module>
  </div>
</template>

<script>
export default {
  components: {
    'custom-module': () => import('@/components/CustomModule/Index.vue')
  },
  data () {
    return {
      formId: this.$route.params.id
    }
  }
}
</script>
